<template>
  <div class="container" v-title :data-title="$t('i18n.staffState')">
    <div id="outer-title">{{ $t("i18n.staffState") }}</div>
    <div v-loading="loading">
      <jl-table
        :tableData="tableData"
        @init="init"
        :options="options"
        :columns="columns"
        :operates="operates"
        :total="total"
        :height="$defined.HEIGHT - 360 + 'px'"
      >
      </jl-table>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import jlTable from "../../components/table";
export default {
  name: "MpBoard",
  components: {
    jlTable,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const router = useRouter();
    const state = reactive({
      formInline: {
        pageNumber: 1,
        pageSize: 10,
      },
      staffStatus: [],
      // table数据
      tableData: [],
      options: {
        // table 的参数
        isAdd: false, // 是否启用新增功能
        isEdit: false, // 是否启用编辑功能
        isDelete: false, // 是否启用删除功能
        highlightCurrentRow: false, // 是否支持当前行高亮显示
        multiSelect: false, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      columns: [
        // 需要展示的列
        {
          prop: "number",
          label: "staffAccount",
          align: "center",
          width: 100,
          search: true,
          type: "input",
        },
        {
          prop: "name",
          label: "staffName",
          align: "center",
          width: 100,
          search: true,
          type: "input",
        },
        {
          prop: "currentState",
          label: "currentStatus",
          align: "center",
          formatter: (row) => {
            let current = state.staffStatus.filter((item) => {
              return item.value == row.currentState;
            });
            return current.length === 0
              ? t("i18n.notSynchronized")
              : t("i18n." + current[0].code);
          },
          search: true,
          type: "select",
          data: [],
          props: { label: "code", value: "value" },
          uploadUrl: "current_state",
        },
        {
          prop: "billAllCount",
          label: "total",
          align: "center",
        },
        {
          prop: "billValidCount",
          label: "finishedOrders",
          align: "center",
        },
        {
          prop: "byCount",
          label: "halfMonth",
          align: "center",
        },
        {
          prop: "jdCount",
          label: "quarter",
          align: "center",
        },
        {
          prop: "bnCount",
          label: "halfAYear",
          align: "center",
        },
        {
          prop: "qnCount",
          label: "yearEnd",
          align: "center",
        },
        {
          prop: "wxCount",
          label: "repair",
          align: "center",
        },
        {
          prop: "jxCount",
          label: "trapped",
          align: "center",
        },
        {
          prop: "zwxCount",
          label: "TOFIX",
          align: "center",
        },
        {
          prop: "jjCount",
          label: "rejectingTimes",
          align: "center",
        },
        {
          prop: "noEndCount",
          label: "unfinishedOrders",
          align: "center",
          width: 100,
        },
        {
          prop: "excellent",
          label: "excellent",
          align: "center",
        },
        {
          prop: "good",
          label: "good",
          align: "center",
        },
        {
          prop: "common",
          label: "general",
          align: "center",
        },
        {
          prop: "poor",
          label: "poor",
          align: "center",
        },
        {
          prop: "noComment",
          label: "noEvaluation",
          align: "center",
        },
      ],
      operates: {
        // 列操作按钮
        width: 100,
        fixed: "right",
        list: [
          {
            id: "1",
            label: "route",
            icon: "el-icon-position",
            show: proxy.$defined.btnPermission("历史轨迹"),
            plain: false,
            disabled: false,
            type: "inner",
            method: (index, row) => {
              enterRoute(row.uid);
            },
          },
          {
            id: "2",
            label: "export",
            icon: "el-icon-upload2",
            class: "export",
            show: true,
            type: "outer",
            method: (formInline) => {
              personDownload(formInline);
            },
          },
        ],
      },
      total: 0,
      loading: true,
    });

    const getStaffStatus = () => {
      proxy.$api.common.getLineageByCode("current_state").then((res) => {
        state.staffStatus = res.data.lineages;
      });
    };
    getStaffStatus();

    const init = async (params) => {
      state.loading = true;
      const { data } = await proxy.$api.maintenance.personPages(params);
      state.tableData = data.records;
      state.total = data.total;
      state.loading = false;
    };

    const enterRoute = (uid) => {
      router.push({
        path: "/maintenance/personnelHistoryRoute",
        query: { uid: uid },
      });
    };

    const personDownload = async (formInline) => {
      state.operates.list[1].loading = true;
      let res = await proxy.$api.maintenance.personDownload(formInline);
      proxy.$defined.exportFunc(res);
      state.operates.list[1].loading = false;
    };

    return {
      ...toRefs(state),
      enterRoute,
      init,
    };
  },
};
</script>
